.headerContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 70%;
}

.title {
    font-weight: 800;
    font-family: 'Poppins';
    font-size: calc(60px + 2vmin);
    color: white;
    text-shadow: 1px 0 0 rgb(146, 146, 146);
    user-select: none;
}